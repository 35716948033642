import { LocalizationService } from '../Localization/localization.service';

const CHECKLIST_LOCALE_KEY = 'settings.checklists_locale';

export type TranslationValue = { label: string; translation: string };
export type TranslationDictionnary = Record<
  string,
  {
    valid: boolean;
    values: TranslationValue[];
  }
>;

/*
 * Note: this PR takes the device locale (for example fr-fr)
 * and make it match with the available translations, to find
 * a case insensitive match. If found, it returns it (fr-FR)
 * to provide the correct key to access the translation
 * dictionnary.
 */
function caseInsensitiveMatch(
  locale: string,
  availableTranslations?: TranslationDictionnary
): string {
  if (!availableTranslations || !locale) {
    return '';
  }

  const availableLocales = Object.keys(availableTranslations);
  let matchingLocale = '';

  availableLocales.some((l) => {
    if (l.toLowerCase() === locale.toLowerCase()) {
      matchingLocale = l;
      return true;
    }
    return false;
  });

  return matchingLocale;
}

export default function (
  localizationService: LocalizationService,
  profileService
): ng.IFilterFunction {
  'ngInject';
  // eslint-disable-next-line complexity
  return (
    originalTranslation: string,
    availableTranslations?: TranslationDictionnary
  ): string => {
    const profile = profileService.getLastGotProfile();
    const localePreference = profile.preferences?.find(
      ({ name }) => name === CHECKLIST_LOCALE_KEY
    );
    const deviceLocale = localizationService.getFullDeviceLang();
    const deviceLang = localizationService.getDeviceLang();

    let translations = { valid: false, values: [] as TranslationValue[] };

    const matchingLocalePreference = caseInsensitiveMatch(
      localePreference?.value as string,
      availableTranslations
    );
    const matchingLocale = caseInsensitiveMatch(
      deviceLocale,
      availableTranslations
    );
    const matchingLang = caseInsensitiveMatch(
      deviceLang,
      availableTranslations
    );

    if (
      matchingLocalePreference &&
      availableTranslations?.[matchingLocalePreference]
    ) {
      translations = availableTranslations[matchingLocalePreference];
    } else if (matchingLocale && availableTranslations?.[matchingLocale]) {
      translations = availableTranslations[matchingLocale];
    } else if (matchingLang && availableTranslations?.[matchingLang]) {
      translations = availableTranslations[matchingLang];
    }

    if (translations && translations.valid && translations.values) {
      const matchingValue = translations.values.find(
        (value) => value.label === originalTranslation
      );

      return matchingValue ? matchingValue.translation : originalTranslation;
    }

    return originalTranslation;
  };
}
