const DB_TRANSACTIONS_MODE = {
  READ: 'readonly',
  WRITE: 'readwrite',
};

import { path } from 'ramda';

const isRegExp = (input) =>
  Object.prototype.toString.call(input) === '[object RegExp]';

const filterHelper = (data, key, value, listComparator) => {
  const boolMatch = (elem, filterValue) =>
    Boolean(elem) === Boolean(filterValue);
  const regexMatch = (elem, filterValue) => filterValue.test(elem);
  const arrayMatch = (elem, filterValue) => filterValue.includes(elem);
  const simpleMatch = (elem, filterValue) => elem === filterValue;
  const isArrayComparison = (elem, filterValue) =>
    Array.isArray(elem) && Array.isArray(filterValue);
  const getResourceValue = path(key.split('.'));
  const resourceValue =
    getResourceValue(data) || getResourceValue(data.contents);

  if (isArrayComparison(resourceValue, value)) {
    return value.every((filter) =>
      resourceValue.some(
        (value) =>
          (listComparator && listComparator(filter, value)) ||
          angular.equals(filter, value)
      )
    );
  }
  if (isRegExp(value)) {
    return regexMatch(resourceValue, new RegExp(value, 'i'));
  }
  if (Array.isArray(value)) {
    return arrayMatch(resourceValue, value);
  }
  if (typeof value === 'boolean') {
    return boolMatch(resourceValue, value);
  }
  if (typeof value === 'function') {
    return value(resourceValue);
  }
  return simpleMatch(resourceValue, value);
};

export class IndexedDBStore {
  constructor($q, storeName, database) {
    this.$q = $q;
    this.storeName = storeName;
    this.database = database;
  }

  initTransaction(mode) {
    // it is important to get store and run transaction
    // in the same tick (synchronously). Without that in Safari
    // transaction is closed before we even try to run it
    return this.database.then(
      (db) => () =>
        db.transaction(this.storeName, mode).objectStore(this.storeName)
    );
  }

  getLocal(id) {
    return this.initTransaction(DB_TRANSACTIONS_MODE.READ).then((getStore) =>
      getStore().get(id)
    );
  }

  listLocal() {
    return this.initTransaction(DB_TRANSACTIONS_MODE.READ).then((getStore) =>
      getStore().getAll()
    );
  }

  countLocal() {
    return this.initTransaction(DB_TRANSACTIONS_MODE.READ).then((getStore) =>
      getStore().count()
    );
  }

  saveLocal(id, entity) {
    return this.initTransaction(DB_TRANSACTIONS_MODE.WRITE)
      .then((getStore) => getStore().put(entity, id))
      .then((id) => this.getLocal(id));
  }

  saveBulkLocal(entities) {
    return this.initTransaction(DB_TRANSACTIONS_MODE.WRITE)
      .then((getStore) => {
        const store = getStore();

        return Promise.all(
          entities.map((entity) => {
            store.put(entity, entity.id);
          })
        );
      })
      .then((ids) => {
        return Promise.all(ids.map((id) => this.getLocal(id)));
      });
  }

  queryLocal(params, limitParams = {}, sortParams = [], listComparator) {
    const firstIndex = limitParams.offset;
    const lastIndex =
      limitParams.offset && limitParams.limit
        ? limitParams.offset + limitParams.limit
        : limitParams.limit;

    return this.listLocal().then((data) => {
      const necessaryParams = Object.entries(params);

      const filteredData = data.filter((curr) =>
        necessaryParams.every(([key, value]) =>
          filterHelper(curr, key, value, listComparator)
        )
      );
      return this.sortLocal(filteredData, sortParams).slice(
        firstIndex,
        lastIndex
      );
    });
  }

  /**
   *
   * @param {*} sortParams priority of sort params is from left to right
   * To achieve this we perform sort in reversed order of what is passed
   * as `sortParams` argument.
   */
  sortLocal(data, sortParams) {
    if (!sortParams.length) {
      return data;
    }
    const { key, desc } = sortParams.pop();
    return this.sortLocal(
      data.sort((a, b) => {
        return (!a[key] && !a.contents[key]) || // undefined value is treated as lowest
          (!desc && (a[key] || a.contents[key]) >= (b[key] || b.contents[key]))
          ? 1
          : -1;
      }),
      sortParams
    );
  }

  updateLocal(entity) {
    const entityId = entity.id || entity._id;
    if (!entityId) {
      throw new Error('E_BACKUP_UPDATE_FAILED');
    }
    return this.initTransaction(DB_TRANSACTIONS_MODE.WRITE)
      .then((getStore) => getStore().put(entity, entityId))
      .then((id) => this.getLocal(id));
  }

  deleteLocal(id) {
    return this.initTransaction(DB_TRANSACTIONS_MODE.WRITE).then((getStore) =>
      getStore().delete(id)
    );
  }

  deleteBulkLocal(ids) {
    return this.initTransaction(DB_TRANSACTIONS_MODE.WRITE).then((getStore) => {
      const store = getStore();

      return Promise.all(ids.map((id) => store.delete(id)));
    });
  }

  bulkDocsLocal(entities) {
    const deleteEntitiesId = entities
      .filter((entity) => entity._deleted)
      .map((entity) => entity.id);

    const upsertEntities = entities.filter((entity) => !entity._deleted);
    const deleteOperationsPromise = this.deleteBulkLocal(deleteEntitiesId);
    const saveOperationsPromise = this.saveBulkLocal(upsertEntities);

    return this.$q
      .all([deleteOperationsPromise, saveOperationsPromise])
      .then(([deleteResult, saveResult]) => {
        return [...deleteResult, ...saveResult];
      });
  }

  listIndexes() {
    return this.initTransaction(DB_TRANSACTIONS_MODE.READ).then(
      (getStore) => getStore().indexNames
    );
  }

  getByIndex(indexName, value) {
    return this.initTransaction(DB_TRANSACTIONS_MODE.READ).then((getStore) =>
      getStore().index(indexName).getAll(IDBKeyRange.only(value))
    );
  }

  deleteByIndex(indexName, value) {
    return this.getByIndex(indexName, value).then((data) =>
      data.forEach((el) => this.deleteLocal(el.id))
    );
  }
}
