import { PingService } from './ping.service';

export default angular
  .module('sfMobile.services.ping', [
    'sfMobile.config.server',
    'sfMobile.services.api-url',
    'sfMobile.services.error-messages',
    'sfMobile.constants',
  ])
  .factory('pingService', PingService).name;
