import { BackEndAuthConfig, AuthConfig } from '../../API/auth/auth.service';
import { Device } from '@capacitor/device';

export type BaseConfig = {
  ANGULAR_DEBUG_DATA: boolean;
  API_BASEURL: string;
  APP_VERSION: string;
  AUTH0_DISABLED: boolean;
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  AUTH0_TOKEN_ROTATION: number;
  AUTH_BASEURL: string;
  DEFAULT_LANGUAGE: string;
  ENABLE_BUG_REPORTS: boolean;
  ENV: string;
  GOOGLE_MAP_API_KEY: string;
  INTERCOM_APP_ID: string;
  LANGUAGES_AVAILABLES: string[];
  SATISMETER_APP_ID: string;
  SEGMENT_ID: string;
  CONFIG_SERVER: string;
  LOCAL: boolean;
  BUILD_CHINA: boolean;
};

export type DatadogLogConfig = {
  DATADOG_CLIENT_TOKEN: string;
  DATADOG_SITE: string;
  DATADOG_SERVICE: string;
  DATADOG_SESSION_RATE: number;
  DATADOG_SEND_ERRORS: boolean;
  DATADOG_ENABLED: boolean;
};

export class ConfigService {
  BaseConfig: BaseConfig;
  AuthConfig: AuthConfig;
  DatadogConfig: DatadogLogConfig;
  private configFetchTimeout = 1000;
  private authConfigPath = 'auth';
  private datadogConfigPath = 'datadog';

  constructor(
    private ConfigServer,
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private localStorageService: ng.local.storage.ILocalStorageService,
    private $window: ng.IWindowService
  ) {
    'ngInject';
    this.BaseConfig = this.ConfigServer;
  }

  getDefaultLanguage(): string {
    return this.BaseConfig.DEFAULT_LANGUAGE;
  }

  isChinaBuild(): boolean {
    return this.BaseConfig.BUILD_CHINA;
  }

  getRemoteAuthConfig(): ng.IPromise<BackEndAuthConfig> {
    return this.$http
      .get<BackEndAuthConfig>(
        `${this.ConfigServer.CONFIG_SERVER}/${this.authConfigPath}`,
        {
          timeout: this.configFetchTimeout,
        }
      )
      .then(({ data }) => {
        return data;
      });
  }

  getRemoteDatadogLogConfig(): ng.IPromise<DatadogLogConfig> {
    return this.$http
      .get<DatadogLogConfig>(
        `${this.ConfigServer.CONFIG_SERVER}/${this.datadogConfigPath}`,
        {
          timeout: this.configFetchTimeout,
        }
      )
      .then(({ data }) => {
        return data;
      });
  }

  getLocalDatadogLogConfig(): DatadogLogConfig {
    const config: DatadogLogConfig = this.ConfigServer;
    return {
      DATADOG_CLIENT_TOKEN: config.DATADOG_CLIENT_TOKEN,
      DATADOG_SITE: config.DATADOG_SITE,
      DATADOG_SERVICE: config.DATADOG_SERVICE,
      DATADOG_SEND_ERRORS: config.DATADOG_SEND_ERRORS,
      DATADOG_SESSION_RATE: config.DATADOG_SESSION_RATE,
      DATADOG_ENABLED: config.DATADOG_ENABLED,
    };
  }

  getAuth0Configuration(): ng.IPromise<AuthConfig> {
    if (this.BaseConfig.ENV === 'development') {
      return this.$q.resolve({
        client_id: this.BaseConfig.AUTH0_CLIENT_ID,
        domain: this.BaseConfig.AUTH0_DOMAIN,
        audience: 'http://localhost:4321',
        enabled: false,
        tokenRotation: this.BaseConfig.AUTH0_TOKEN_ROTATION,
      });
    } else {
      return this.getRemoteAuthConfig().then((backendAuthConfig) => {
        return this.shouldUseChinaConfiguration().then(
          (shouldUseChinaConfiguration) => {
            if (shouldUseChinaConfiguration && backendAuthConfig.cn) {
              return {
                ...backendAuthConfig.cn.mobile,
                audience: backendAuthConfig.cn.api.audience,
                tokenRotation: backendAuthConfig.cn.api.tokenRotation,
              };
            }
            return {
              ...backendAuthConfig.eu.mobile,
              audience: backendAuthConfig.eu.api.audience,
              tokenRotation: backendAuthConfig.eu.api.tokenRotation,
            };
          }
        );
      });
    }
  }

  getDatadogLogConfiguration(): ng.IPromise<DatadogLogConfig> {
    if (this.DatadogConfig) {
      return this.$q.when(this.DatadogConfig);
    }
    return this.getRemoteDatadogLogConfig()
      .then((datadogConfig) => {
        this.DatadogConfig = {
          ...this.getLocalDatadogLogConfig(),
          ...datadogConfig,
        };
        return this.DatadogConfig;
      })
      .catch((error) => {
        console.log(error);
        const config = this.getLocalDatadogLogConfig();
        return this.$q.when(config);
      });
  }

  shouldUseChinaConfiguration(): ng.IPromise<boolean> {
    const configurationArea =
      this.localStorageService.get('configuration_area');

    // If explicitly set, return area
    if (configurationArea === 'china') {
      return this.$q.when(true);
    }
    if (configurationArea === 'world') {
      return this.$q.when(false);
    }

    // If not explicitly set, base result on device locale
    const navigatorLang = this.$window.navigator?.language ?? '';

    if (navigatorLang.toLowerCase().includes('zh')) {
      return this.$q.when(true);
    }

    return Device.getLanguageCode()
      .then((deviceLang) => {
        if (deviceLang.value.toLowerCase().includes('zh')) {
          return true;
        }

        return false;
      })
      .catch(() => {
        return false;
      });
  }
}
