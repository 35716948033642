import { datadogLogs } from '@datadog/browser-logs';
import { User } from '../../..';
import { DatadogLogConfig } from '../Config/config.service';

export class DatadogService {
  Datadog = datadogLogs;
  Logger = datadogLogs.logger;
  private enabled = false;
  started = false;

  constructor(private $log: ng.ILogService) {
    'ngInject';
  }

  init(config: DatadogLogConfig, env, version) {
    this.enabled = config.DATADOG_ENABLED;
    if (this.enabled) {
      this.Datadog.init({
        env,
        version,
        clientToken: config.DATADOG_CLIENT_TOKEN,
        site: config.DATADOG_SITE,
        service: config.DATADOG_SERVICE,
        forwardErrorsToLogs: config.DATADOG_SEND_ERRORS,
        sessionSampleRate: config.DATADOG_SESSION_RATE,
      });
      this.started = true;
      this.$log.info('Datadog RUM SDK started');
      this.$log.debug(config);
    } else {
      this.$log.info('Datadog RUM SDK disabled');
      this.$log.debug(config);
    }
  }

  setUser(user: User): void {
    this.Datadog.setUser({
      id: user._id,
      org_id: user.contents.organisation_id,
      username: `${user.contents.firstName} ${user.contents.lastName}`,
      email: user.contents.email,
    });
  }

  getUser(): any {
    return this.Datadog.getUser();
  }

  reset(): void {
    this.Datadog.clearUser();
  }

  addInfo(msg: string, context: any): void {
    if (this.enabled && this.started) {
      this.Logger.info(msg, context);
    }
  }

  addWarn(msg: string, context: any): void {
    if (this.enabled && this.started) {
      this.Logger.warn(msg, context);
    }
  }

  addError(error: Error, cause?: Error): void {
    if (this.enabled && this.started) {
      this.Logger.error(
        error?.message ?? 'An error occurred',
        { ...(cause ? { cause } : {}) },
        error
      );
    }
  }
}
