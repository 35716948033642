import moment from 'moment';

export class MonthYearSelectorController {
  constructor(dateFormatService, platformService, objectIdService) {
    'ngInject';
    this.moment = moment;
    this.model = new Date();
    this.getMonthAndYearFormatted = dateFormatService.getMonthAndYearFormatted;
    this.isBrowser = platformService.isBrowser();
    // without unique id click on the labels stop working at some point
    this.id = objectIdService.create();
  }

  $onInit() {
    this.updateModel();
  }

  $onChanges(changes) {
    if (changes.date) {
      this.updateModel();
    }
  }

  updateModel() {
    this.model = this.date ? this.moment(this.date).toDate() : new Date();
    const formattedDate = this.getMonthAndYearFormatted(
      this.moment(this.model)
    );

    this.viewValue = formattedDate.replace(
      formattedDate[0],
      formattedDate[0].toUpperCase()
    );
  }

  onSelectChange() {
    return this.onSelect({ momentDate: this.moment(this.model) });
  }
}
