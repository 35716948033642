import { pathOr } from 'ramda';
import type {
  Campaign,
  CampaignsStatistics,
  CampaignStatistics,
  PerStoreObjectiveCompletion,
  PerStoreObjectiveCompletionExpected,
  PerUserObjectiveCompletion,
  PerUserObjectiveCompletionExpected,
  PosAuditStatistics,
  PreviousAnswersPayload,
  SimplifiedCampaignObjectiveCompletion,
} from './campaigns';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { DB_SCHEMA } from '../../../core/database.config';
import { IAPIList, ObjectId } from '../../../index';
import { CampaignsUtilsService } from '../../../reactive-campaigns/services/campaigns-utils.service';
import { AccessRightsService } from '../../Utils/AccessRights/access-rights.service';
import { DataAccessLayerService } from '../../Utils/CRUD/crud-service';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import { ImageService } from '../../Utils/Image/image.service';
import { ReportsService } from '../reports/reports.service';

const USEFUL_FIELDS = ['contents', 'statistics', 'i18n'];

type CoverSize = 'big' | 'medium' | 'small';

export enum CampaignPreferencesKeys {
  allowEditByOwner = 'allowEditByOwner',
  allowEditByAnalyst = 'allowEditByAnalyst',
  allowEditByRespondent = 'allowEditByRespondent',
  allowEditPendingValidation = 'allowEditPendingValidation',
  allowEditValidated = 'allowEditValidated',
}

export class CampaignsService {
  crud: DataAccessLayerService<Campaign>;
  COVER_SIZES: Record<CoverSize, typeof IMAGE_SIZES[keyof typeof IMAGE_SIZES]>;

  /* @ngInject */
  constructor(
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private accessRightsService: AccessRightsService,
    private crudFactory: CrudFactory<Campaign>,
    private databaseSchema: DB_SCHEMA,
    private profileService,
    private campaignsUtilsService: CampaignsUtilsService,
    private imageService: ImageService,
    private sfPOVService,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private reportsService: ReportsService
  ) {
    const tableConfig = this.databaseSchema.tables.campaigns;

    this.crud = this.crudFactory(tableConfig.table_name, {
      default_params: {
        mode: 'compact',
        fields: USEFUL_FIELDS,
      },
      exclude_offline_params: ['state'],
      take_care_of_user_profile: false,
      backup: {
        indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
      },
    });
    this.COVER_SIZES = {
      big: this.SF_IMAGE_SIZES.SQUARE_BIG,
      medium: this.SF_IMAGE_SIZES.RECTANGLE_MEDIUM,
      small: this.SF_IMAGE_SIZES.SQUARE_SMALL,
    };
  }

  /**
   * Tries to find the campaign in local db
   * Fetches from api if not exists and saves to db storage
   *
   * @param id string campaign id
   */
  async getOne(id: string, skipLocalSave = false): Promise<Campaign> {
    const entityFromLocalDB: Campaign | null = await this.crud
      .getLocal(id)
      .catch(() => null);

    if (entityFromLocalDB) {
      return entityFromLocalDB;
    }

    const entryFromApi: Campaign = await this.crud.getRemote(
      id,
      { fields: USEFUL_FIELDS },
      { pov: 'organisation' }
    );

    if (skipLocalSave) {
      return entryFromApi;
    }
    await this.crud.saveLocal(id, entryFromApi);

    return entryFromApi;
  }

  getCampaignObjectiveCompletion(
    campaignId: ObjectId
  ): ng.IPromise<
    (SimplifiedCampaignObjectiveCompletion & CampaignStatistics) | undefined
  > {
    return this.crud
      .getRemoteWithDefaultPOV(campaignId, {
        fields: ['contents', 'statistics'],
      })
      .catch(() => this.crud.getLocal(campaignId))
      .then(
        (
          campaign
        ): ng.IPromise<
          | (SimplifiedCampaignObjectiveCompletion & CampaignStatistics)
          | undefined
        > => {
          const statistics = this.getCampaignStatistic(campaign);
          const stats = statistics.objectivesCalculations;

          if (!stats) {
            return this.$q.resolve(undefined);
          }

          let promise;

          if (this.campaignsUtilsService.isPerUserObjectiveCompletion(stats)) {
            promise = this.formatPerUserObjectiveStats(campaign);
          }

          if (this.campaignsUtilsService.isPerStoreObjectiveCompletion(stats)) {
            promise = this.formatPerStoreObjectiveStats(campaign);
          }

          if (!promise) {
            return this.$q.resolve(undefined);
          }

          return promise.then((value) => {
            return {
              ...statistics,
              ...value,
            };
          });
        }
      );
  }

  formatPerUserObjectiveStats(
    campaign: Campaign
  ): ng.IPromise<{ perUser: PerUserObjectiveCompletionExpected } | undefined> {
    return this.profileService.getProfile().then((profile) => {
      const isRespondent = campaign?.resolved.collector_ids.includes(
        profile._id
      );

      const statistics = this.getCampaignStatistic(campaign);
      const stats =
        statistics.objectivesCalculations as PerUserObjectiveCompletion;

      if (isRespondent) {
        return { perUser: stats.perUser.personal };
      }

      return undefined;
    });
  }

  formatPerStoreObjectiveStats(
    campaign: Campaign
  ): ng.IPromise<
    { perStore: PerStoreObjectiveCompletionExpected } | undefined
  > {
    return this.profileService.getProfile().then((profile) => {
      const isRespondent = campaign?.resolved.collector_ids.includes(
        profile._id
      );
      const isRootOrAdmin = this.accessRightsService.isAtLeastAdmin();
      const statistics = this.getCampaignStatistic(campaign);
      const stats =
        statistics.objectivesCalculations as PerStoreObjectiveCompletion;

      if (isRespondent) {
        return {
          // because the root or admin may not be assigned to the stores
          perStore: isRootOrAdmin ? stats.perStore.all : stats.perStore.scoped,
        };
      }

      return undefined;
    });
  }

  getCampaignStatistic(campaign: Campaign) {
    const statistics = campaign?.statistics;

    if (Array.isArray(statistics)) {
      const mostRecent = statistics?.[0];

      return mostRecent;
    }

    return statistics;
  }

  getCampaignsList(): ng.IPromise<Campaign[]> {
    const filters = {
      $and: [
        { 'contents.state': { $ne: 'deactivated' } },
        {
          $or: [{ archived: { $eq: false } }, { archived: { $exists: false } }],
        },
      ],
    };

    return this.sfPOVService
      .pBuildURL(`/campaigns`, { pov: 'organisation' })
      .then((url) =>
        this.$http.get<IAPIList<Campaign>>(url, { params: { filters } })
      )
      .then((response) => response.data.entries);
  }

  getCampaignsListByIds(campaignIds: ObjectId[]): ng.IPromise<Campaign[]> {
    const filters = {
      campaign_id: {
        $in: campaignIds,
      },
    };

    return this.sfPOVService
      .pBuildURL(`/campaigns`, { pov: 'organisation' })
      .then((url) =>
        this.$http.get<IAPIList<Campaign>>(url, { params: { filters } })
      )
      .then((response) => response.data.entries);
  }

  getLastReportAnswers(
    campaign_id: string,
    params: Record<string, string> = {}
  ): ng.IPromise<PreviousAnswersPayload> {
    const url = `/campaigns/${campaign_id}/lastAnswers`;

    return this.crud.simpleApiList(url, params, false, {
      pov: 'organisation',
    }) as unknown as ng.IPromise<PreviousAnswersPayload>;
  }

  getCampaignCoverUrl(
    campaign: Campaign,
    size: CoverSize
  ): ng.IPromise<string> {
    return this.imageService.getSizedUrlFromId(
      pathOr<string>('', ['contents', 'picture', '_id'], campaign),
      this.COVER_SIZES[size]
    );
  }

  getFilesPath(campaign: Campaign): string {
    return `campaign/${campaign._id}/files`;
  }

  getReportsStatistics(
    campaignId: ObjectId,
    params?: Record<string, string>
  ): ng.IPromise<CampaignsStatistics> {
    const url = `/campaigns/${campaignId}/reports/statistics`;

    return this.sfPOVService
      .pBuildURL(url, { pov: 'organisation' })
      .then((url) => this.$http.get(url, { params: params }))
      .then((res) => res.data);
  }

  getCampaignPosAuditStatistics(
    campaignId: ObjectId,
    placeId: ObjectId
  ): ng.IPromise<PosAuditStatistics> {
    return this.crud
      .getRemote(
        campaignId,
        { fields: ['posAuditStatistics'] },
        { pov: 'organisation' }
      )
      .catch(() => this.crud.getLocal(campaignId))
      .then((campaign) => {
        const statistics = campaign?.posAuditStatistics;

        return statistics && statistics[placeId] ? statistics[placeId] : {};
      });
  }

  isDraft(campaign: Campaign) {
    return campaign.contents.state === 'draft' || campaign.movedToDraft;
  }
}
