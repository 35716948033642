import { PICTURE_SYSTEM_IDENTIFIERS } from '../../../constants/picture-system.constants';
import { FileChooserService } from '../../../services/Utils/FileChooser/file-chooser.service';
import { NewsfeedPopupService } from '../newsfeed-popup/newsfeed-popup.service';
import type {
  NewsfeedPostResourceType,
  ResourceFile,
  ResourcesSelectedCb,
} from '../../types';
import { FileValidatorService } from '../../../services/Utils/FileValidator/file-validator.service';
import type { NgfFile } from '../../../services/Utils/FileValidator/file-validator.service';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import { CameraService } from '../../../services/Utils/Camera/camera.service';
import { PictureSystemService } from '../../../services/Utils/PictureSystem/picture-system.service';
import {
  DOCUMENT_UPLOAD_OPTIONS,
  FILES_UPLOAD_OPTIONS,
  IMAGE_UPLOAD_OPTIONS,
  VIDEO_UPLOAD_OPTIONS,
} from '../../../constants/files-upload-options.constant';
import { FileSystemService } from '../../../services/Utils/FileSystem/file-system.service';

export class NewsfeedResourcesSelectorService {
  onResourcesSelected: ResourcesSelectedCb;
  isDisabled: boolean;
  imageOptions: typeof IMAGE_UPLOAD_OPTIONS;
  documentOptions: typeof DOCUMENT_UPLOAD_OPTIONS;
  videoOptions: typeof VIDEO_UPLOAD_OPTIONS;
  TYPE_OPTIONS: {
    image: typeof IMAGE_UPLOAD_OPTIONS;
    document: typeof DOCUMENT_UPLOAD_OPTIONS;
    video: typeof VIDEO_UPLOAD_OPTIONS;
  };

  // eslint-disable-next-line max-params
  constructor(
    private $window: ng.IWindowService,
    private $q: ng.IQService,
    private objectIdService: ObjectIdService,
    private cameraService: CameraService,
    private pictureSystemService: PictureSystemService,
    private filesSystemService: FileSystemService,
    private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS,
    private fileChooserService: FileChooserService,
    private $translate: ng.translate.ITranslateService,
    private fileValidatorService: FileValidatorService,
    private newsfeedPopupService: NewsfeedPopupService
  ) {
    'ngInject';
    this.imageOptions = this.SF_FILES_UPLOAD_OPTIONS.IMAGE;
    this.documentOptions = this.SF_FILES_UPLOAD_OPTIONS.DOCUMENT;
    this.videoOptions = this.SF_FILES_UPLOAD_OPTIONS.VIDEO;
    this.TYPE_OPTIONS = {
      image: this.imageOptions,
      document: this.documentOptions,
      video: this.videoOptions,
    };
  }

  setResourceSelectCallback(resourcesSelectedCb: ResourcesSelectedCb): void {
    this.onResourcesSelected = resourcesSelectedCb;
  }

  hasToResize(width: number, height: number): boolean {
    return (
      width > this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxWidth ||
      height > this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxHeight
    );
  }

  selectDocumentsOnMobile(): ng.IPromise<void> {
    return this.fileChooserService
      .getDocument()
      .then((blob: Blob | File) =>
        this.getValidFromSelectedFiles([blob], 'document')
      )
      .then((blobs) => this.onDeviceFilesSelect(blobs, 'document'));
  }

  selectFilesOnBrowser(
    files: File[],
    invalidFiles: NgfFile[],
    type: NewsfeedPostResourceType
  ): void {
    if (invalidFiles.length > 0) {
      this.showErrors(invalidFiles, this.TYPE_OPTIONS[type].maxSize);
    }
    // on mobile on the web there is still a chance to fool the browser with a file type
    const validFiles = this.getValidFromSelectedFiles(files, type);

    this.onDeviceFilesSelect(validFiles, type);
  }

  selectFromGallery(
    imagesCount: number,
    type: NewsfeedPostResourceType
  ): ng.IPromise<void> {
    if (type === 'video') {
      return this.fileChooserService
        .getVideo()
        .then((blob: Blob) => this.getValidFromSelectedFiles([blob], 'video'))
        .then((blobs) => this.onDeviceFilesSelect(blobs, 'video'));
    }

    return this.pictureSystemService
      .getPictureFromSource(PICTURE_SYSTEM_IDENTIFIERS.LIBRARY, imagesCount)
      .then((paths) =>
        this.$q.all(
          paths.map((path) => this.filesSystemService.getBlobFromPath(path))
        )
      )
      .then((blobs) => this.getValidFromSelectedFiles(blobs, 'image'))
      .then((blobs) => this.onDeviceFilesSelect(blobs, 'image'));
  }

  takePicture(): ng.IPromise<void> {
    return this.cameraService
      .getPhotoAsFile()
      .then((file) => this.onDeviceFilesSelect([file], 'image'));
  }

  takeVideo(): ng.IPromise<void> {
    return (
      this.cameraService
        .takeVideo()
        .then(({ fullPath, name }) =>
          this.filesSystemService
            .getBlobFromPath(fullPath)
            .then((blob) =>
              this.filesSystemService.createFileFromBlob(blob, name)
            )
        )
        .then((blob: Blob) => this.getValidFromSelectedFiles([blob], 'video'))
        .then(([blob]) => this.onDeviceFilesSelect([blob], 'video'))
    );
  }

  private onDeviceFilesSelect(blobs: Blob[], type: NewsfeedPostResourceType) {
    if (!blobs.length) {
      return;
    }

    (blobs as ResourceFile[]).forEach((blob) => {
      blob._id = this.objectIdService.create();
      blob.url = this.$window.URL.createObjectURL(blob);
    });
    this.onResourcesSelected({ $files: blobs as ResourceFile[], $type: type });
  }

  private getValidFromSelectedFiles(
    blobs: Blob[],
    type: NewsfeedPostResourceType
  ) {
    const maxFilesAllowed = type === 'image' ? this.imageOptions.maxFiles : 1;
    const { valid, invalid } = this.fileValidatorService.filterByValidity(
      blobs,
      type,
      maxFilesAllowed
    );

    if (invalid.length > 0) {
      this.showErrors(invalid, this.TYPE_OPTIONS[type].maxSize);
    }
    return valid;
  }

  showErrors(invalidFiles: NgfFile[], maxSize: string): void {
    const errorMessage = {
      maxSize: this.$translate.instant('NEWSFEED_EDIT_POST_RESOURCE_MAX_SIZE', {
        nb: maxSize,
      }),
      maxFiles: this.$translate.instant(
        'NEWSFEED_EDIT_POST_RESOURCE_MAX_FILES'
      ),
      pattern: this.$translate.instant('NEWSFEED_EDIT_POST_RESOURCE_PATTERN'),
    };
    const errors = this.fileValidatorService.getFilesGroupedByErrors(
      invalidFiles,
      errorMessage
    );

    this.newsfeedPopupService.showFileErrors(errors);
  }
}
