const PLACE_VIEW_ANALYTICS = {
  placeInfos: 'Place Info',
  placeDocuments: 'Place Documents',
  placeTasks: 'Place Tasks',
  placeMissions: 'Place Missions',
  placeReports: 'Place Reports',
  placeGallery: 'Place Gallery',
  placeCalendarEvents: 'Place Calendar Events',
};

export default function placesRouteConfig($stateProvider) {
  'ngInject';

  // Routes.
  $stateProvider
    .state('index.places', {
      url: '/places',
      abstract: true,
      views: {
        'tab-places': {
          template: '<ion-nav-view></ion-nav-view>',
        },
      },
    })
    .state('index.places.list', {
      url: '',
      template: `
        <ion-view class="sf_view__has_nav_bar">
          <sf-places
            profile="$ctrl.profile">
          </sf-places>
        </ion-view>
      `,
      controller: PlacesViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Places List',
    })
    .state('index.places.details', {
      url: '/:placeId',
      abstract: true,
      template: `<ion-nav-view></ion-nav-view>`,
    })
    .state('index.places.details.main', {
      url: '',
      params: {
        targetTab: null,
        shouldEditParams: false,
      },
      template: `
        <ion-view>
          <sf-place
            organisation="$ctrl.organisation"
            profile="$ctrl.profile"
            place="$ctrl.place"
            target-tab="$ctrl.targetTab"
          >
          </sf-place>
        </ion-view>
      `,
      controller: PlaceViewController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpPlace: resolvePlace,
        rsvpProfile: resolveProfile,
      },
      analyticsName: 'Place Detail',
    })
    .state('index.places.details.missions', {
      url: '/missions/:missionsType',
      template: `
        <ion-view>
          <sf-place-missions-list
            place="$ctrl.place"
            profile="$ctrl.profile"
            missions-type="$ctrl.missionsType"
            title="{{ ::$ctrl.title | translate }}"
          >
          </sf-place-missions-list>
        </ion-view>
      `,
      controller: PlaceMissionsController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpPlace: resolvePlace,
      },
    })
    .state('index.places.details.campaigns', {
      url: '/campaigns',
      template: `
        <ion-view>
          <sf-place-campaigns-list
            place="$ctrl.place"
            profile="$ctrl.profile"
          >
          </sf-place-campaigns-list>
        </ion-view>
      `,
      controller: PlaceCampaignsController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpPlace: resolvePlace,
      },
    })
    .state('index.places.details.documents', {
      url: '/documents',
      template: `
        <ion-view>
          <sf-place-documents
            place="$ctrl.place"
          >
          </sf-place-documents>
        </ion-view>
      `,
      controller: PlaceDocumentsController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpPlace: resolvePlace,
      },
    })
    .state('index.places.details.gallery', {
      url: '/gallery',
      template: `
        <ion-view>
          <sf-place-gallery
            place="$ctrl.place"
          >
          </sf-place-gallery>
        </ion-view>
      `,
      controller: PlaceGalleryController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpPlace: resolvePlace,
      },
    })
    .state('index.places.details.reports', {
      url: '/reports',
      template: `
        <ion-view>
          <sf-place-reports-list
            place="$ctrl.place"
          >
          </sf-place-reports-list>
        </ion-view>
      `,
      controller: PlaceReportsController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpPlace: resolvePlace,
      },
    })
    .state('index.places.details.calendar-events', {
      url: '/calendar-events',
      template: `
        <ion-view>
          <sf-place-calendar-events-list place="$ctrl.place">
          </sf-place-calendar-events-list>
        </ion-view>
      `,
      controller: PlaceCalendarEventsController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpPlace: resolvePlace,
      },
    })
    .state('index.places.details.tasks', {
      url: '/tasks',
      template: `
        <ion-view>
          <sf-place-tasks-tabs
            place="$ctrl.place"
            profile="$ctrl.profile"
          >
          </sf-place-tasks-tabs>
        </ion-view>
      `,
      controller: PlaceTasksController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpPlace: resolvePlace,
      },
    });

  // @ngInject
  function PlaceMissionsController(
    rsvpPlace,
    rsvpProfile,
    $stateParams,
    segmentService
  ) {
    this.missionsType = $stateParams.missionsType;
    this.place = rsvpPlace;
    this.profile = rsvpProfile;
    if (this.missionsType === 'ponctual') {
      this.title = 'PLACE_MISSIONS_ONE_TIME';
    } else {
      this.title = 'PLACE_MISSIONS_PERMANENT';
    }
    segmentService.page(PLACE_VIEW_ANALYTICS.placeMissions);
  }

  // @ngInject
  function PlaceCampaignsController(rsvpPlace, rsvpProfile, segmentService) {
    this.place = rsvpPlace;
    this.profile = rsvpProfile;
    segmentService.page(PLACE_VIEW_ANALYTICS.placeMissions);
  }
  // @ngInject
  function PlacesViewController(rsvpProfile) {
    this.profile = rsvpProfile;
  }

  // @ngInject
  function PlaceViewController(
    rsvpPlace,
    rsvpProfile,
    rsvpOrganisation,
    $stateParams
  ) {
    this.place = rsvpPlace;
    this.profile = rsvpProfile;
    this.organisation = rsvpOrganisation;
    this.targetTab = $stateParams.targetTab;
  }

  // @ngInject
  function PlaceDocumentsController(rsvpPlace, segmentService) {
    this.place = rsvpPlace;
    segmentService.page(PLACE_VIEW_ANALYTICS.placeDocuments);
  }

  // @ngInject
  function PlaceGalleryController(rsvpPlace, segmentService) {
    this.place = rsvpPlace;
    segmentService.page(PLACE_VIEW_ANALYTICS.placeGallery);
  }

  //@ngInject
  function PlaceCalendarEventsController(rsvpPlace, segmentService) {
    this.place = rsvpPlace;
    segmentService.page(PLACE_VIEW_ANALYTICS.placeCalendarEvents);
  }

  // @ngInject
  function PlaceReportsController(rsvpPlace, segmentService) {
    this.place = rsvpPlace;
    segmentService.page(PLACE_VIEW_ANALYTICS.placeReports);
  }

  // @ngInject
  function PlaceTasksController(rsvpPlace, rsvpProfile, segmentService) {
    this.place = rsvpPlace;
    this.profile = rsvpProfile;
    segmentService.page(PLACE_VIEW_ANALYTICS.placeTasks);
  }

  /* eslint-disable max-params */
  // @ngInject
  function resolvePlace(
    $stateParams,
    $q,
    $translate,
    placesService,
    popupService
  ) {
    const showError = () =>
      popupService.showError({
        title: $translate.instant('PLACE_DETAIL_INACCESSIBLE_ERROR_TITLE'),
        desc: $translate.instant('PLACE_DETAIL_INACCESSIBLE_ERROR_DESC'),
        iconName: 'item-danger',
      });

    return placesService.get($stateParams.placeId).catch(() => {
      showError();
      return $q.reject();
    });
  }
}

function resolveProfile(profileService) {
  'ngInject';
  return profileService.getProfile().catch(() => {});
}
