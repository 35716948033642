/* eslint-disable complexity */
import { last } from 'ramda';

export function RequestsPaginateService($q, logService) {
  'ngInject';

  function RequestsPaginate(request, options = {}) {
    this.request = request;

    this.limit = options.limit;
    this.firstFetchLimit = options.firstFetchLimit || this.limit;
    this.relatedEntitiesKeys = options.relatedEntitiesKeys || [];

    this.cursor = options.cursor;

    this.reset();
  }

  RequestsPaginate.prototype.call = call;
  RequestsPaginate.prototype.reset = reset;
  RequestsPaginate.prototype.getRelatedEntities = getRelatedEntities;
  RequestsPaginate.prototype.canCallMore = canCallMore;
  RequestsPaginate.prototype.removeItem = removeItem;

  /**
   * Reset the state
   * @param  {Object}  params - request params
   * @return {Promise}        - request result
   * @this RequestsPaginate
   */
  function call(params = {}, ...args) {
    logService.placeLog(`[BUGS-2566] requests-paginate.service.js | call`, {
      params,
      pending: this.pending,
      isReset: this.isReset,
      placesCount: this.datas.entities.length,
      placesIds: this.datas.entities.map((place) => place._id),
    });
    const timestamp = (this.timestamp = Date.now());

    if (this.pending) {
      // to prevent race conditions bugs after reset
      return this.isReset ? this.pending : $q.resolve(this.datas);
    }

    if (!this.canCallMore()) {
      return $q.resolve(this.datas);
    }

    params = {
      ...params,
      limit: this.datas.entities.length ? this.limit : this.firstFetchLimit,
    };

    if (this.datas.entities.length) {
      const pagination = this.cursor
        ? { cursor: this.cursor(last(this.datas.entities)) }
        : { skip: this.datas.entities.length };

      params = {
        ...params,
        ...pagination,
      };
    }

    logService.placeLog(
      `[BUGS-2566] requests-paginate.service.js | before request`,
      {
        params,
        placesCount: this.datas.entities.length,
        placesIds: this.datas.entities.map((place) => place._id),
      }
    );
    this.pending = this.request(params, ...args)
      .then((response) => {
        logService.placeLog(
          `[BUGS-2566] requests-paginate.service.js | call response`,
          {
            oldPlacesCount: this.datas.entities.length,
            oldPlacesIds: this.datas.entities.map((place) => place._id),
            newPlacesCount: response.entries ? response.entries.length : 0,
            newPlacesIds: response.entries
              ? response.entries.map((place) => place._id)
              : [],
          }
        );
        if ((timestamp || 0) < (this.timestamp || 1) && !this.isReset) {
          return this.datas;
        }

        const { count, meta = {} } = response;

        this.datas = {
          entities: this.datas.entities.concat(response.entries),
          newEntities: response.entries || [],
          relatedEntities: this.relatedEntitiesKeys.reduce((output, key) => {
            output[key] = {
              ...(output[key] || {}),
              ...(response[key] || {}),
            };
            return output;
          }, this.datas.relatedEntities),
          count: count || meta.count,
        };
        logService.placeLog(
          `[BUGS-2566] requests-paginate.service.js | return new datas`,
          {
            placesCount: this.datas.entities.length,
            placesIds: this.datas.entities.map((place) => place._id),
          }
        );

        return this.datas;
      })
      .finally(() => {
        this.pending = null;
        this.isReset = false;
      });

    return this.pending;
  }

  /**
   * Reset the state
   * @return {Boolean} - true
   * @this RequestsPaginate
   */
  function reset() {
    this.isReset = true;
    this.timestamp = Date.now();
    this.pending = null;
    this.datas = {
      entities: [],
      newEntities: [],
      relatedEntities: {},
      count: 1,
    };

    return true;
  }

  /**
   * Get the related entities
   * @param {String} key - entity key
   * @return {Object} - The entities
   * @this RequestsPaginate
   */
  function getRelatedEntities(key) {
    const relatedEntites = this.datas.relatedEntities;

    return relatedEntites[key] || {};
  }

  /**
   * Get true if a new request is allowed
   * @return {Boolean} - If the request is allowed
   * @this RequestsPaginate
   */
  function canCallMore() {
    if (!this.datas.count) {
      return !!this.datas.newEntities.length;
    }
    return this.datas.entities.length < this.datas.count;
  }

  /**
   * When an item is removed from a list - the skip param and datas.count must be updated
   * @this RequestsPaginate
   * @param {Object} item to be removed from the array of data
   * @return {Boolean} - true
   */
  function removeItem(item) {
    this.datas.count--;
    this.datas.entities = this.datas.entities.filter(
      (arrayItem) => arrayItem._id.toString() !== item._id.toString()
    );

    return true;
  }

  return RequestsPaginate;
}
